
import { defineComponent, ref, computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

import Nav from '../../components/Nav/Nav.vue'
import PhoneBackBtn from '../../components/Nav/PhoneBackBtn.vue'
import FooterNav from '../../components/Footer/FooterNav.vue'
import AuthorInfo from './cpns/AuthorInfo.vue'
import ArticleByUserList from './cpns/ArticleByUserList.vue'
import AuthorList from './cpns/AuthorList.vue'
import message from '../../utils/message'

export default defineComponent({
  name: 'usermenu',
  components: {
    Nav,
    PhoneBackBtn,
    FooterNav,
    AuthorInfo,
    ArticleByUserList,
    AuthorList,
  },
  setup(props, content) {
    const route = useRoute()
    const userBoxRef = ref<HTMLDivElement>()
    let tabIndex = ref('0')
    let isMousedown = ref(false)
    watchEffect(()=>{
      if(route.params.userid){
        tabIndex.value='0'
      }
    })
    // onMounted(() => {
    //   userBoxRef.value!.addEventListener('touchstart',(e)=>{
    //     x=e.targetTouches[0].pageX
    //     isMousedown.value=true
    //   })
    //   userBoxRef.value!.addEventListener('Touchend',(e)=>{
    //     isMousedown.value=false
    //     x=0
    //   })
    //   userBoxRef.value!.addEventListener('touchmove',(e)=>{
    //     if(isMousedown.value){
    //       if(x+20 <e.targetTouches[0].pageX && e.targetTouches[0].pageX-x>0){
    //         if(tabIndex.value==='5'){
    //           tabIndex.value='0'
    //           console.log('加右1')
    //         }else{
    //           console.log('加2')
    //           let num=Number(tabIndex.value+'')
    //           tabIndex.value=(++num)+''
    //         }
    //       }else if(x-20 > e.targetTouches[0].pageX && e.targetTouches[0].pageX-x < 0){
    //         if(tabIndex.value==='0'){
    //           tabIndex.value='5'
    //           console.log('减1')
    //         }else{
    //           let num=Number(tabIndex.value+'')
    //           console.log('减2')
    //           tabIndex.value=(--num)+''
    //         }
    //       }
    //     }
    //   })
    // })
    return {
      tabIndex,
      userBoxRef,
    }
  },
})
