
import { defineComponent, ref, onMounted ,nextTick} from 'vue'
import ArticleCard from '../../../views/article/ArticleCard.vue'
import {useRoute} from 'vue-router'

import { Calendar } from '@element-plus/icons-vue'

import { getArticleByStars ,getHistory,getArticleByUser} from '../../../service/article/index'

import { IDataType } from '../../../service/article/type'

import message from '../../../utils/message'
import { formatUtcString } from '../../../utils/date'

export default defineComponent({
  name: 'ArticleByUserList',
  components: {
    ArticleCard,
    Calendar
  },
  props: ['type'],
  setup(props, content) {
    const route=useRoute()
    let type=props.type
    let ArticleAllList = ref<any>([])
    let current = 1
    let size = 10
    let isNullArticle = ref(false)
    let loading = ref(false)
    let isByAuthor=false
    const userId = route.params.userid+''
    function init() {
      loading.value = false
      isNullArticle.value = false
      ArticleAllList.value.length = 0
      current = 1
      size = 10
      isByAuthor=false
    }
    async function getArticleAllList() {
      loading.value = true
      let articleData!: IDataType
      switch(type){
        case 'byStar':
          articleData = await getArticleByStars({ current, size, userId })
          break
        case 'byHistory':
          articleData = await getHistory({ current, size, userId ,orderBy:'visitTime.desc'})
          break
        case 'byAuthor':
          articleData = await getArticleByUser({ current, size, userId })
          isByAuthor=true
          break
        default:
          isNullArticle.value=true
          return
      }
      if (articleData.success) {
        message.success('获取文章成功')
        if (articleData.data.pages === articleData.data.current || articleData.data.pages===0) {
          isNullArticle.value = true
          loading.value=false
        }
        if(isByAuthor){
          Array.from(articleData.data.records).forEach(item=>{
            ArticleAllList.value.push({'article':item})
          })
        }else{
          ArticleAllList.value.push(...articleData.data.records)
        }
        console.log('文章列表',ArticleAllList.value)
        loading.value = false
        current++
      } else {
        message.warning(articleData.data)
        loading.value=false
        isNullArticle.value=true
      }
    }
    onMounted(() => {
      init()
      getArticleAllList()
    })
    return {
      ArticleAllList,
      isNullArticle,
      loading,
      getArticleAllList,
      formatUtcString
    }
  },
})
