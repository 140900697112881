
import { defineComponent,onMounted,ref,nextTick,watchEffect} from 'vue'
import {useRoute,useRouter} from 'vue-router'
import { useStore} from '../../../store/'

import message from '../../../utils/message'

import FollowedItem from './FollowedItem.vue'

import {getById,follow,unFollow} from '../../../service/user/user'


export default defineComponent({
  name: 'AuthorInfo',
  props:['userId'],
  components:{
    FollowedItem
  },
  setup(props,content){
    const route = useRoute()
    const router = useRouter()
    let userInfo=ref({})
    async function getByIdFun() {
        const data=await getById(route.params.userid+'')
        if(data.success){
          nextTick(()=>{
              userInfo.value=data.data
          })
        }else{
          message.error(data.data)
        }
    }
    watchEffect(()=>{
      if(route.params.userid){
        getByIdFun()
      }
    })
    return{
        userInfo,
    }
  }
})
